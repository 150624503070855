* {
  box-sizing: border-box;
  --foreground: #111827;
  --background: #f0f0f0;
}

body {
  font-family: sans-serif;
  line-height: 1.5;
  color: var(--foreground);
  background-color: var(--background);
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
}

#app {
  width: 100%;
  max-width: 72rem;
  min-height: 100dvh;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

#app > main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: start;
  gap: 4rem;
}
